import { baseApi } from '../../app/baseApi'

export const roleSlice = baseApi.injectEndpoints({ 
    endpoints: (builder) => ({ 
      GetRoles: builder.query({
        query: () => '/auth/organization/roles',
        providesTags: (result = [], error, arg) => [
          'Roles',
          ...result.map(({ id }) => ({ type: 'Roles', id })),
        ], 
         transformResponse: (response) => {   
          return response.data.roles
        },
      }), 
      GetRole: builder.query({
        query: (roleId) => `/auth/organization/roles/${roleId}`,
        providesTags: (result, error, arg) => [{ type: 'Roles', id: arg }],
        transformResponse: (response) => {  
          return response.data.role
        },
      }),   
      CreateRole: builder.mutation({
        query: (role) => ({
          url: '/auth/organization/roles',
          method: 'POST',
          body: role,
        }),
        invalidatesTags: ['Roles'],
      }),  
      EditRole: builder.mutation({
         
        query: (role) => ({
          url: `/auth/organization/roles/${role.get('id')}`,
          method: 'POST',
          body: role,
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Roles', id: arg.id }],
      }),
      DeleteRole: builder.mutation({
          query: (roleId) => ({
            url: `/auth/organization/roles/${roleId}`,
            method: 'DELETE',          
          }),
          invalidatesTags: (result, error, arg) => [{ type: 'Roles', id: arg.id }],
        }), 
    })
})

export const {
    useGetRolesQuery,
    useGetRoleQuery,
    useCreateRoleMutation,
    useEditRoleMutation, 
    useDeleteRoleMutation,
} = roleSlice