import { baseApi } from '../../app/baseApi';
 
export const accountSlice = baseApi.injectEndpoints({ 
  endpoints: (builder) => ({ 
    GetUsers: builder.query({
      query: () => '/auth/organization/users',
      providesTags: (result = [], error, arg) => [
        'Users',
        ...result.map(({ id }) => ({ type: 'Users', id })),
      ], 
       transformResponse: (response) => {   
        return response.data.users
      },
    }),
    GetNonHierarchyUsers: builder.query({
      query: () => '/auth/organization/hierarchies/non-users',
      providesTags: (result = [], error, arg) => [
        'Users',
        ...result.map(({ id }) => ({ type: 'Users', id })),
      ], 
       transformResponse: (response) => {   
        return response.data.users
      },
    }), 
    GetUser: builder.query({
      query: (userId) => `/auth/organization/users/${userId}`,
      providesTags: (result, error, arg) => [{ type: 'Users', id: arg }],
      transformResponse: (response) => {  
        return response.data.user
      },
    }),   
    CreateUser: builder.mutation({
      query: (user) => ({
        url: '/auth/organization/users',
        method: 'POST', 
        body: user,
      }), 
      invalidatesTags: ['Users'],
    }),  
    EditUser: builder.mutation({
      query: (user) => ({
        url: `/auth/organization/users/${user.id}`,
        method: 'PUT',
        body: user,
      }),
      refetchOnMountOrArgChange:true,
      invalidatesTags: (result, error, arg) => [{ type: 'Users', id: arg.id }],
    }),
    DeleteUser: builder.mutation({
        query: (userId) => ({
          url: `/auth/organization/users/${userId}`,
          method: 'DELETE',          
        }),
        refetchOnMountOrArgChange:true,
        invalidatesTags: (result, error, arg) => [{ type: 'Users', id: arg.id }],
      }), 
    PostAccountLogin: builder.mutation({
      query: (loginData) => ({
        url: '/auth/public/user/login',
        method: 'POST',
        body: loginData,
      }),      
      transformResponse: (response) => {     
        localStorage.removeItem('user'); 
        localStorage.setItem('user', JSON.stringify(response.data)); 
        return response.data
      },
      invalidatesTags: ['Account','Users'],
    }), 
  }),
})

export const {
    useGetUsersQuery,
    useGetUserQuery,
    useCreateUserMutation,
    useEditUserMutation, 
    useDeleteUserMutation,
    usePostAccountLoginMutation,
    useGetNonHierarchyUsersQuery
} = accountSlice
