import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'  
import { BehaviorSubject } from 'rxjs'; 

const currentUserSubject = new BehaviorSubject(JSON.parse(localStorage.getItem('user')));

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({ baseUrl: `${process.env.REACT_APP_IBUY_SERVICE_HOST}`,  prepareHeaders: (headers) => {
 
    let token =''
   
    if(currentUserSubject.value === null){
       token = JSON.parse(localStorage.getItem('user'))?.token 
    }
    
    if (currentUserSubject.value?.token) {    
       token =  currentUserSubject.value.token;       
    } 
    headers.set('authorization', `Bearer ${token}`)
    return headers
  }}),
  tagTypes: ['Account','Users','Permissions','Roles','Organizations','Hierarchies','Orders','Reports',
  'ProductCategories','Brands','Products','ShopProducts','CartProducts','RFQ','RFQPRODUCTS','TC','Menu','Pages'],
  endpoints: () => ({}),
});