import { baseApi } from '../../app/baseApi'

export const permissionSlice = baseApi.injectEndpoints({ 
    endpoints: (builder) => ({ 
      GetPermissions: builder.query({
        query: () => '/auth/organization/permissions',
        providesTags: (result = [], error, arg) => [
          'Permissions',
          ...result.map(({ id }) => ({ type: 'Permissions', id })),
        ], 
         transformResponse: (response) => {    
          return response.data.permissions
        },
      }), 
    GetPermission: builder.query({
      query: (permissionId) => `/auth/organization/permissions/${permissionId}`,
      providesTags: (result, error, arg) => [{ type: 'Users', id: arg }],
      transformResponse: (response) => {  
        return response.data.permission
      },
    }),        
    EditPermission: builder.mutation({
        query: (permission) => ({
          url: `/auth/organization/permissions/${permission.id}`,
          method: 'PUT',
          body: permission,
        }),
        invalidatesTags: (result, error, arg) => [{ type: 'Permissions', id: arg.id }],
      }),
    })
})

export const {
    useGetPermissionsQuery,
    useGetPermissionQuery,
    useEditPermissionMutation
} = permissionSlice