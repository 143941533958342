import React, { lazy } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AccessibleNavigationAnnouncer from './components/AccessibleNavigationAnnouncer';
import PrivateRoute from './routes/PrivateRoute';

const Layout = lazy(() => import('./containers/Layout'));

const PublicLayout = lazy(() => import('./pages/common/PublicLayout'));
const LogIn = lazy(() => import('../src/features/accounts/LogIn'));  
const ForgotPassword = lazy(() => import('../src/features/accounts/ForgotPassword'));

const Products = lazy(() => import('./pages/Products')) 
const Home = lazy(()=>import('./pages/Home'))

function App() {
  return (
    <>
      <Router>
        <AccessibleNavigationAnnouncer />
        <Routes> 
        <Route path="/products" element={<Products />} /> 
          <Route path="/login" element={<LogIn />} /> 
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/" element={<Home />} /> 
          {/* Place auth routes over this */} 
            <Route path="*" element={<PublicLayout />} /> 

          {/* Place auth routes over this */}
          <Route exact element={<PrivateRoute />}>
            <Route path="/app/*" element={<Layout />} />
          </Route>

          {/* Place new routes over this */}

          {/* If you have an index page, you can remothis Redirect */}
          <Route path="*" element={<LogIn />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
