import { combineReducers } from 'redux';
import { accountSlice } from '../features/accounts/accountSlice'
import { roleSlice } from '../features/roles/roleSlice'
import { permissionSlice } from '../features/permissions/permissionSlice'


const rootReducer = combineReducers({ 
    [accountSlice.reducerPath]: accountSlice.reducer,
    [roleSlice.reducerPath]: roleSlice.reducer,
    [permissionSlice.reducerPath]: permissionSlice.reducer,
});


 export default rootReducer;