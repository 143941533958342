import jwt_decode from "jwt-decode";
import { store } from '../app/store'
import { baseApi } from '../app/baseApi'

const token = (JSON.parse(localStorage.getItem('user'))?.token) ? jwt_decode(JSON.parse(localStorage.getItem('user'))?.token) : null;
const rights = (token) ? token.permissions : ["unauthorized"];
const user_id = (token) ? token.sub : null;

export function isLoggedIn() {
	return !!localStorage.getItem('user')
}

export function userPermissions() {
	return rights
}

export function getUserId() {
	return user_id
}

export function clearGlobalState() {
	store.dispatch(baseApi.util.resetApiState())
	return true
}

export function currentUser() {
	const token = (JSON.parse(localStorage.getItem('user'))?.token) ? jwt_decode(JSON.parse(localStorage.getItem('user'))?.token) : null;
	return (token?.sub) ? token?.sub : null
}

export function isJWTExpired() {
	if (token) { 		 
		if (token.exp * 1000 <= new Date().getTime()) {
			return true;
		}
	}
	return false;
}

export function isJsonString(str) {
	let val;
    try {
		val = JSON.parse(JSON.stringify(str));		
    } catch (e) { 
        return false;
    }
    return true;
}

/* Permission Chips */
export function createbrand() {
	return (rights.indexOf("create-brand") > -1);
}
export function dashboardorders() {
	return (rights.indexOf("dashboard-orders") > -1);
}
export function superadmin() {
	return (rights.indexOf("super-admin") > -1);
}

export function reportlevelselect(){
	return (rights.indexOf("report-level") > -1);
}

export function createcart() {
	return (rights.indexOf("create-cart") > -1);
}
export function createcategory() {
	return (rights.indexOf("create-category") > -1);
}
export function createhierarchy() {
	return (rights.indexOf("create-hierarchy") > -1);
}
export function createlevel() {
	return (rights.indexOf("create-level") > -1);
} 
export function createorder() {
	return (rights.indexOf("create-order") > -1);
}
export function createorganization() {
	return (rights.indexOf("create-organization") > -1);
}

export function createorganizationproduct() {
	return (rights.indexOf("create-organization-product") > -1);
}

export function createpermission() {
	return (rights.indexOf("create-permission") > -1);
}
export function createproduct() {
	return (rights.indexOf("create-product") > -1);
}

export function createquotation() {
	return (rights.indexOf("create-quotation") > -1);
}
export function createrfq() {
	return (rights.indexOf("create-rfq") > -1);
}
export function createrole() {
	return (rights.indexOf("create-role") > -1);
}
export function createterm() {
	return (rights.indexOf("create-term") > -1);
}
export function createuser() {
	return (rights.indexOf("create-user") > -1);
}
export function deletebrand() {
	return (rights.indexOf("delete-brand") > -1);
}
export function deletecart() {
	return (rights.indexOf("delete-cart") > -1);
}
export function deletecategory() {
	return (rights.indexOf("delete-category") > -1);
}
export function deletehierarchy() {
	return (rights.indexOf("delete-hierarchy") > -1);
}
export function deletelevel() {
	return (rights.indexOf("delete-level") > -1);
}
export function deleteorder() {
	return (rights.indexOf("delete-order") > -1);
}
export function deleteorganization() {
	return (rights.indexOf("delete-organization") > -1);
}
export function deleteorganizationproduct() {
	return (rights.indexOf("delete-organization-product") > -1);
}
export function deletepermission() {
	return (rights.indexOf("delete-permission") > -1);
}
export function deleteproduct() {
	return (rights.indexOf("delete-product") > -1);
} 
export function deletequotation() {
	return (rights.indexOf("delete-quotation") > -1);
}
export function deleterfq() {
	return (rights.indexOf("delete-rfq") > -1);
}
export function deleterole() {
	return (rights.indexOf("delete-role") > -1);
}
export function deleteterm() {
	return (rights.indexOf("delete-term") > -1);
}
export function deleteuser() {
	return (rights.indexOf("delete-user") > -1);
}
export function readbrand() {
	return (rights.indexOf("read-brand") > -1);
}
export function readcart() {
	return (rights.indexOf("read-cart") > -1);
}
export function readcategory() {
	return (rights.indexOf("read-category") > -1);
}
export function readhierarchy() {
	return (rights.indexOf("read-hierarchy") > -1);
}
export function readlevel() {
	return (rights.indexOf("read-level") > -1);
}
export function readorder() {
	return (rights.indexOf("read-order") > -1);
}
export function readorganization() {
	return (rights.indexOf("read-organization") > -1);
}
export function readorganizationproduct() {
	return (rights.indexOf("read-organization-product") > -1);
}
export function readpermission() {
	return (rights.indexOf("read-permission") > -1);
} 
export function readproduct() {
	return (rights.indexOf("read-product") > -1);
}
export function readquotation() {
	return (rights.indexOf("read-quotation") > -1);
}
export function readrfq() {
	return (rights.indexOf("read-rfq") > -1);
}
export function readrole() {
	return (rights.indexOf("read-role") > -1);
}
export function readterm() {
	return (rights.indexOf("read-term") > -1);
}
export function readuser() {
	return (rights.indexOf("read-user") > -1);
}
export function updatebrand() {
	return (rights.indexOf("update-brand") > -1);
}
export function updatecart() {
	return (rights.indexOf("update-cart") > -1);
}
export function updatecategory() {
	return (rights.indexOf("update-category") > -1);
}
export function updatehierarchy() {
	return (rights.indexOf("update-hierarchy") > -1);
}
export function updatelevel() {
	return (rights.indexOf("update-level") > -1);
}
export function updateorder() {
	return (rights.indexOf("update-order") > -1);
} 
export function updateorganization() {
	return (rights.indexOf("update-organization") > -1);
}
export function updateorganizationproduct() {
	return (rights.indexOf("update-organization-product") > -1);
}
export function updatepermission() {
	return (rights.indexOf("update-permission") > -1);
}
export function updateproduct() {
	return (rights.indexOf("update-product") > -1);
} 
export function updatequotation() {
	return (rights.indexOf("update-quotation") > -1);
}
export function updaterfq() {
	return (rights.indexOf("update-rfq") > -1);
}
export function updaterole() {
	return (rights.indexOf("update-role") > -1);
}
export function updateterm() {
	return (rights.indexOf("update-term") > -1);
}
export function updateuser() {
	return (rights.indexOf("update-user") > -1);
}
export function updatebudget() {
	return (rights.indexOf("update-budget") > -1);
}

export function createbudget() {
	return (rights.indexOf("create-budget") > -1);
}
export function isAppover() {
	return (rights.indexOf("approver") > -1);
}
export function formatCurrency(amount) {
    const formattedAmount = (Math.round(amount * 100) / 100).toLocaleString('en-IN', { style: 'currency', currency: 'INR' });
    return formattedAmount.includes('(') ? formattedAmount.replace('-', '-₹ ').replace('(', '').replace(')', '') : formattedAmount;
}

export function disableDates () {
    var today = new Date();
    var dd = today.getDate() + 1;
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();

    dd = dd < 10 ? '0' + dd : dd; 
    mm = mm < 10 ? '0' + mm : mm;

    return yyyy + "-" + mm + "-" + dd;
}